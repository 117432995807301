import React from 'react';

import { Transfer } from '@types/transfer';

import StatusCircle from 'components/StatusCircle';

export const getStatusBadge = (item: Transfer): React.ReactElement => {
  if (item.status === 'pending_transfer' || item.status === 'processing') {
    return <StatusCircle colorScheme="yellow">Pendente</StatusCircle>;
  }

  if (item.status === 'transferred') {
    return <StatusCircle colorScheme="green">Successo</StatusCircle>;
  }

  if (item.status === 'failed' || item.status === 'canceled') {
    return <StatusCircle colorScheme="red">Falha</StatusCircle>;
  }

  return <StatusCircle colorScheme="gray">Outro</StatusCircle>;
};
