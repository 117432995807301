/* eslint-disable prettier/prettier */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Text,
  VStack,
  Button,
  Skeleton,
} from '@chakra-ui/react';

import { Balance } from '@types/balance';

import api from 'services/api';

import Box from 'components/Box';
import { ModalRootProps } from 'components/Modal/Root';

import useManagedAccount from 'hooks/useManagedAccount';

interface Balances {
  method: string;
  name: string;
  color: string;
  value: number;
}

const ModalMethodBalanceWithdrawRequest: React.FC<ModalRootProps> = ({
  handleClose,
  ...restProps
}) => {
  const history = useHistory();

  const { account } = useManagedAccount();

  const [data, setData] = useState<Balance>({} as Balance);
  const [isLoading, setIsLoading] = useState(true);

  const getData = useCallback(async () => {
    setIsLoading(true);
    setData({} as Balance);

    try {
      const response = await api.get('/account/balance/summary', {
        params: {
          'filter[account]': account.id,
        },
      });

      setData(response.data?.data);
    } catch (err) {
      setData({} as Balance);
    } finally {
      setIsLoading(false);
    }
  }, [account.id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const balances: Balances[] = useMemo(
    () => [
      {
        method: 'default',
        name: '(Pix, Cartão e Boleto)',
        color: 'orange',
        value: data?.pagarme?.total_available?.total || 0,
      },
      {
        method: 'paypal',
        name: 'Paypal',
        color: 'blue',
        value: data?.paypal?.total_available?.total || 0,
      },
      // {
      //   method: 'parcelex',
      //   name: 'Parcelex',
      //   color: 'teal',
      //   value: 157776.32,
      // },
      // {
      //   method: 'crypto',
      //   name: 'Criptomoeda',
      //   color: 'yellow',
      //   value: 157776.32,
      // },
    ],
    [data],
  );

  const handleRequestWithdraw = (method: string): void => {
    history.push(`/financial/transfers/bank/request/${method}`);

    handleClose();
  };

  return (
    <Drawer size="md" {...restProps}>
      <DrawerOverlay />

      <DrawerContent borderBottomLeftRadius="base" borderTopLeftRadius="base">
        <DrawerHeader>Saldo por método de pagamento</DrawerHeader>

        <DrawerCloseButton />

        <DrawerBody>
          <Flex
            direction="column"
            padding="4"
            borderRadius="base"
            textAlign="center"
          >
            {isLoading ? (
              <Skeleton height={35} width={150} marginX='auto' marginBottom={3} />
            ) : (
                <Text variant="heading-3" marginBottom="1" color="green.500">
                  {Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data.full?.total_available?.total || 0)}
                </Text>
              )}

            <Text variant="smallcaps" color="gray.600">
              Saldo total disponível
            </Text>
          </Flex>

          <VStack
            spacing="6"
            alignItems="center"
            justifyContent="flex-start"
            marginTop="6"
          >
            {balances.map((item: Balances) => (
              <Flex direction="column" width="100%">
                <Flex
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Flex direction="column">
                    <Text
                      variant="smallcaps"
                      color="gray.600"
                      marginBottom="0.5"
                    >
                      Saldo total disponível
                    </Text>

                    <Text variant="subheading-1" color={`${item.color}.400`}>
                      {item.name}
                    </Text>
                  </Flex>

                  <Flex
                    height="2px"
                    bg="gray.100"
                    width="auto"
                    flex="1"
                    marginX="4"
                  />

                  <Flex direction="column" textAlign="right">
                    <Box>
                      <Text variant="heading-4">
                        {isLoading ? (
                          <Skeleton height={25} width={120} marginX='auto' />
                        ) : (
                            Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(item.value || 0)
                          )}
                      </Text>

                      <Button
                        size="xs"
                        variant="link"
                        marginTop="2"
                        colorScheme={item.color}
                        onClick={() => handleRequestWithdraw(item.method)}
                        isDisabled={item.value === 0}
                      >
                        Solicitar saque
                      </Button>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ModalMethodBalanceWithdrawRequest;
