/* eslint-disable react/require-default-props */
import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { format } from 'date-fns';

import {
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  VStack,
  Icon,
  Skeleton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Grid,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Divider,
  DrawerFooter,
} from '@chakra-ui/react';

import { Balance } from '@types/balance';

import { Info as InfoIcon } from '@styled-icons/bootstrap/Info';

import api from 'services/api';

import { ModalRootProps } from 'components/Modal/Root';
import Tooltip from 'components/Tooltip';

import useManagedAccount from 'hooks/useManagedAccount';

interface RenderBalanceProps {
  value: number;
  name: string;
  hasPercent?: boolean;
  percent?: number;
  isDebit?: boolean;
  isEquity?: boolean;
  color?: string;
  tooltip: JSX.Element;
}

const ModalDashboardBalanceHistory: React.FC<ModalRootProps> = ({
  ...restProps
}) => {
  const { account } = useManagedAccount();

  const [loading, setLoading] = useState<boolean>(true);
  const [totalData, setTotalData] = useState<Balance>({} as Balance);
  const [periodData, setPeriodData] = useState<Balance>({} as Balance);

  const [startDateFilter, setStartDateFilter] = useState<string>('');
  const [endDateFilter, setEndDateFilter] = useState<string>('');

  const getTotalData = useCallback(async () => {
    if (!account.id) return;

    setLoading(true);
    setTotalData({} as Balance);

    try {
      const response = await api.get('/account/balance/summary', {
        params: {
          'filter[account]': account.id,
        },
      });

      const balanceValues = response.data.data;

      setTotalData(balanceValues);
    } catch (err) {
      setTotalData({} as Balance);
    } finally {
      setLoading(false);
    }
  }, [account.id]);

  useEffect(() => {
    getTotalData();
  }, [getTotalData]);

  const getDataFilter = useCallback(async () => {
    const startDate = startDateFilter
      ? await format(new Date(startDateFilter), 'yyyy-MM-dd HH:mm').replace(
          ' ',
          'T',
        )
      : '';

    const endDate = endDateFilter
      ? await format(new Date(endDateFilter), 'yyyy-MM-dd HH:mm').replace(
          ' ',
          'T',
        )
      : '';

    return `${startDate},${endDate}`;
  }, [endDateFilter, startDateFilter]);

  const getPeriodData = useCallback(async () => {
    setLoading(true);
    setPeriodData({} as Balance);

    const dateFilter = await getDataFilter();

    try {
      const response = await api.get('/account/balance/summary', {
        params: {
          'filter[account]': account.id,
          'filter[created_at]': dateFilter,
        },
      });

      const balanceValues = response.data.data;

      setPeriodData(balanceValues);
    } catch (err) {
      setPeriodData({} as Balance);
    } finally {
      setLoading(false);
    }
  }, [account.id, getDataFilter]);

  const renderValue = useCallback(
    ({
      name,
      value = 0,
      hasPercent = false,
      percent = 0,
      color = 'gray.800',
      isEquity = false,
      isDebit = false,
      tooltip,
    }: RenderBalanceProps) => {
      return (
        <Flex
          flex={1}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          bg={isEquity ? 'gray.50' : 'transparent'}
          padding="2"
          borderRadius="base"
        >
          <Text
            as={Flex}
            alignItems="center"
            variant="body-2"
            fontWeight={isEquity ? 'bold' : 'normal'}
          >
            {name}

            {hasPercent &&
              (loading ? (
                <Skeleton width="40px" height="15px" marginLeft="1" />
              ) : (
                <Text
                  marginLeft="1"
                  fontSize="smaller"
                  as="small"
                  color="gray.700"
                >
                  ({percent.toFixed(2).toString().replace('.', ',')}%)
                </Text>
              ))}
          </Text>

          <Flex flex={1} height="2px" bg="gray.100" marginX="2" />

          <Text
            as={Flex}
            alignItems="center"
            variant="body-2"
            fontWeight="bold"
            color={color}
            {...(isDebit && { color: 'red.400' })}
          >
            {!loading && isDebit && '-'}

            {loading ? (
              <Skeleton width="80px" height="25px" />
            ) : (
              Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(value)
            )}

            {tooltip && (
              <Tooltip hasArrow label={tooltip} bg="gray.800">
                <Icon
                  as={InfoIcon}
                  w={5}
                  h={5}
                  bg="gray.100"
                  borderRadius="50%"
                  border="none"
                  cursor="pointer"
                  marginLeft="2"
                  color="gray.900"
                />
              </Tooltip>
            )}
          </Text>
        </Flex>
      );
    },
    [loading],
  );

  return (
    <Drawer {...restProps} size="lg">
      <DrawerOverlay />

      <DrawerContent borderTopLeftRadius="base" borderBottomLeftRadius="base">
        <DrawerHeader marginRight="6">
          Veja seu saldo e histórico financeiro
        </DrawerHeader>

        <DrawerCloseButton />

        <DrawerBody>
          <Tabs isFitted>
            <TabList>
              <Tab>Resultado total</Tab>
              <Tab>Resultado por período</Tab>
            </TabList>

            <TabPanels>
              {/* Total result */}
              <TabPanel>
                <VStack marginY={8} spacing={8}>
                  {renderValue({
                    name: 'Vendas',
                    value: totalData.total?.total,
                    tooltip: (
                      <>
                        <Text fontWeight="bold" mb={1}>
                          Vendas
                        </Text>

                        <Text>
                          Este é o montante total em reais das vendas que você
                          alcançou na Cursology.
                        </Text>
                      </>
                    ),
                  })}

                  <VStack width="100%" spacing="1">
                    {renderValue({
                      name: 'Cursology',
                      value: totalData.platform_cost?.total,
                      hasPercent: true,
                      percent: totalData.platform_cost?.percent,
                      isDebit: true,
                      tooltip: (
                        <>
                          <Text fontWeight="bold" mb={1}>
                            Cursology
                          </Text>

                          <Text>
                            Esse é o montante acumulado em taxas cobradas pela
                            utilização dos serviços oferecidos pela plataforma.
                          </Text>
                        </>
                      ),
                    })}

                    {renderValue({
                      name: 'Custo de processamento',
                      value: totalData.transaction_cost?.total,
                      hasPercent: false,
                      // percent: totalData.transaction_cost?.percent,
                      isDebit: true,
                      tooltip: (
                        <>
                          <Text fontWeight="bold" mb={1}>
                            Custo de processamento
                          </Text>

                          <Text>
                            Esse é o montante acumulado em taxas cobradas pela
                            utilização dos serviços oferecidos pela plataforma.
                          </Text>
                        </>
                      ),
                    })}

                    {renderValue({
                      name: 'Custo de NF',
                      value: totalData.tax_invoice_cost?.total,
                      hasPercent: false,
                      percent: totalData.tax_invoice_cost?.percent,
                      isDebit: true,
                      tooltip: (
                        <>
                          <Text fontWeight="bold" mb={1}>
                            Custo de NF
                          </Text>

                          <Text>Custo de emissão da nota fiscal.</Text>
                        </>
                      ),
                    })}

                    {renderValue({
                      name: 'Parcelamento',
                      value: totalData.installment_fee?.total,
                      hasPercent: true,
                      percent: totalData.installment_fee?.percent,
                      isDebit: true,
                      tooltip: (
                        <>
                          <Text fontWeight="bold" mb={1}>
                            Parcelamento
                          </Text>

                          <Text>
                            Custos gerados pelo parcelamento de produtos.
                          </Text>
                        </>
                      ),
                    })}
                  </VStack>

                  <VStack width="100%" spacing="1">
                    {renderValue({
                      name: 'Resultado líquido',
                      value: totalData.liquid?.total,
                      hasPercent: true,
                      percent: totalData.liquid?.percent,
                      tooltip: (
                        <>
                          <Text fontWeight="bold" mb={1}>
                            Resultado líquido
                          </Text>

                          <Text>
                            Este é o valor líquido obtido após deduções e
                            descontos.
                          </Text>
                        </>
                      ),
                    })}

                    {renderValue({
                      name: 'Transferências já realizadas',
                      value: totalData.transfers?.total,
                      tooltip: (
                        <>
                          <Text fontWeight="bold" mb={1}>
                            Transferências já realizadas
                          </Text>

                          <Text>
                            Transações financeiras que foram concluídas com
                            sucesso, os valores já foram transferidos para sua
                            conta.
                          </Text>
                        </>
                      ),
                    })}
                  </VStack>
                </VStack>

                <Flex
                  alignItems="flex-end"
                  direction="column"
                  bg="gray.50"
                  padding="4"
                  borderRadius="base"
                  borderColor="gray.100"
                  borderWidth="1px"
                  mb={2}
                >
                  <Text variant="heading-3" marginBottom="1" color="blue.500">
                    {loading ? (
                      <Skeleton width="124px" height="28px" mb={2} />
                    ) : (
                      Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(totalData.total_balance?.total || 0)
                    )}
                  </Text>

                  <Text variant="smallcaps" color="gray.600">
                    Saldo total
                  </Text>
                </Flex>

                <Flex
                  alignItems="flex-end"
                  direction="column"
                  bg="gray.50"
                  padding="4"
                  borderRadius="base"
                  borderColor="gray.100"
                  borderWidth="1px"
                >
                  <Text variant="heading-3" marginBottom="1" color="green.500">
                    {loading ? (
                      <Skeleton width="124px" height="28px" mb={2} />
                    ) : (
                      Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(totalData.total_available?.total || 0)
                    )}
                  </Text>

                  <Text variant="smallcaps" color="gray.600">
                    Disponível para saque
                  </Text>
                </Flex>
              </TabPanel>

              {/* Result per period */}
              <TabPanel>
                <Flex
                  flexDirection="column"
                  gap={4}
                  border="2px"
                  borderColor="gray.100"
                  borderRadius={10}
                  padding={8}
                  overflowX="auto"
                >
                  <Text
                    fontWeight="bold"
                    fontSize="1rem"
                    textAlign="center"
                    mb={3}
                  >
                    Escolha o período que você deseja ver o resultado
                  </Text>

                  <Grid
                    templateColumns="repeat(auto-fit, minmax(220px, 1fr))"
                    columnGap={2}
                    rowGap={2}
                  >
                    <FormControl>
                      <FormLabel>Data (Início)</FormLabel>

                      <Input
                        type="datetime-local"
                        value={startDateFilter}
                        onChangeCapture={(e) =>
                          setStartDateFilter(e.target.value)
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Data (Fim)</FormLabel>

                      <Input
                        type="datetime-local"
                        value={endDateFilter}
                        onChangeCapture={(e) =>
                          setEndDateFilter(e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Button onClick={() => getPeriodData()} isLoading={loading}>
                    Buscar dados
                  </Button>

                  <Divider my={3} />

                  {periodData && (
                    <VStack marginY={8} spacing={8}>
                      {renderValue({
                        name: 'Vendas',
                        value: periodData.total?.total,
                        tooltip: (
                          <>
                            <Text fontWeight="bold" mb={1}>
                              Vendas
                            </Text>

                            <Text>
                              Este é o montante total em reais das vendas que
                              você alcançou na Cursology.
                            </Text>
                          </>
                        ),
                      })}

                      <VStack width="100%" spacing="1">
                        {renderValue({
                          name: 'Cursology',
                          value: periodData.platform_cost?.total,
                          hasPercent: true,
                          percent: periodData.platform_cost?.percent,
                          isDebit: true,
                          tooltip: (
                            <>
                              <Text fontWeight="bold" mb={1}>
                                Cursology
                              </Text>

                              <Text>
                                Esse é o montante acumulado em taxas cobradas
                                pela utilização dos serviços oferecidos pela
                                plataforma.
                              </Text>
                            </>
                          ),
                        })}

                        {renderValue({
                          name: 'Parcelamento',
                          value: periodData.installments_fee?.total,
                          hasPercent: true,
                          percent: periodData.installments_fee?.percent,
                          isDebit: true,
                          tooltip: (
                            <>
                              <Text fontWeight="bold" mb={1}>
                                Parcelamento
                              </Text>

                              <Text>
                                Custos gerados pelo parcelamento de produtos.
                              </Text>
                            </>
                          ),
                        })}
                      </VStack>

                      {renderValue({
                        name: 'Resultado líquido',
                        value: periodData.liquid?.total,
                        hasPercent: true,
                        percent: periodData.liquid?.percent,
                        tooltip: (
                          <>
                            <Text fontWeight="bold" mb={1}>
                              Resultado líquido
                            </Text>

                            <Text>
                              Este é o valor líquido obtido após deduções e
                              descontos.
                            </Text>
                          </>
                        ),
                      })}
                    </VStack>
                  )}
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </DrawerBody>

        <DrawerFooter>
          <Button
            as={Link}
            to="/financial/extract"
            onClick={() => restProps.handleClose()}
          >
            Mostrar extrato completo
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ModalDashboardBalanceHistory;
