import React from 'react';

import './style.css';

const Switch: React.FC<{
  value: boolean;
  setValue: (on: boolean) => void;
}> = ({ value, setValue }) => {
  return (
    <button
      type="button"
      className={`switch-button ${value ? 'on' : 'off'}`}
      onClick={() => {
        setValue(!value);
      }}
    >
      <div />
    </button>
  );
};

export default Switch;
