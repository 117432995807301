import React, { createContext, useState, ReactNode, useContext } from 'react';

import { requestHeaders, requestHeadersFormData } from 'pages/Marketing/utils';

import AuthContext from 'contexts/AuthContext';

const LocalStorageVendorApiKey = 'cursology:vendor:api-key';
const LocalStorageBearer = '@persisted:cursology:auth';

const VendorContext = createContext(
  {} as {
    getVendorApiKey: () => Promise<any>;
    sendRequest: (
      endpoint: string,
      method: string,
      body?: undefined | any,
      body_type?: undefined | string,
    ) => Promise<any>;
    downloadFile: (endpoint: string) => void;
    queryVendorCourses: () => Promise<any>;
    queryVendorEventTypes: () => Promise<any>;
    queryVendorLeadContacts: (lead_id: number, type: string) => Promise<any>;
    queryVendorLeadRecentActivities: (lead_id: number) => Promise<any>;
  },
);

export const VendorProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [vendorApiKey, setVendorApiKey] = useState<any>();
  // const [vendorId, setVendorId] = useState<any>();
  const { user } = useContext(AuthContext);

  // useEffect(() => {
  //   if (user?.accounts?.length > 0) {
  //     setVendorId(user.accounts[0].id);
  //   }
  // }, [user]);

  const refreshApiKey = async (): Promise<any> => {
    const bearer: string | undefined = localStorage
      .getItem(LocalStorageBearer)
      ?.replaceAll('"', '');

    if (bearer) {
      const response = await fetch(
        `${process.env.REACT_APP_API_MKT_URL}/api/v1/vendors/auth`,
        {
          method: 'GET',
          headers: {
            ...requestHeaders,
            Accept: 'application/json',
            Authorization: `Bearer ${bearer}`,
            'x-vendor-id': String(user?.accounts[0]?.id),
          },
        },
      );

      if (response.ok) {
        const json = await response.json();
        const key = json['api-key'];
        setVendorApiKey(key);
        return { key, vendor_id: String(user?.accounts[0]?.id) };
      }
    }

    return null;
  };

  const getVendorApiKey = async (): Promise<any> => {
    if (vendorApiKey && String(user?.accounts[0]?.id)) {
      return { key: vendorApiKey, vendor_id: String(user?.accounts[0]?.id) };
    }

    const key: any = localStorage.getItem(LocalStorageVendorApiKey);
    if (key) {
      setVendorApiKey(key);
      return { key, vendor_id: String(user?.accounts[0]?.id) };
    }

    const values = await refreshApiKey();
    return values;
  };

  const sendRequest = async (
    endpoint: string,
    method: string,
    body = undefined,
    body_type = 'json',
  ): Promise<any> => {
    const headers: any =
      body_type === 'json'
        ? { ...requestHeaders }
        : { ...requestHeadersFormData };

    const { key, vendor_id } = await getVendorApiKey();
    headers['x-vendor-api-key'] = key;
    headers['x-vendor-id'] = vendor_id;

    let response = await fetch(endpoint, {
      method,
      headers,
      body: JSON.stringify(body),
    });

    if (response.status === 401) {
      const { key: api_key, vendor_id: id } = await refreshApiKey();
      headers['x-vendor-api-key'] = api_key;
      headers['x-vendor-id'] = id;
      response = await fetch(endpoint, {
        method,
        headers,
        body: JSON.stringify(body),
      });
    }

    const json = method !== 'DELETE' ? await response.json() : {};
    if (!response.ok && !json.message) {
      json.message = 'Ocorreu um erro com sua requisição.';
    }

    return {
      success: response.ok,
      data: json,
    };
  };

  const downloadFile = async (url: string): Promise<void> => {
    try {
      // Criar uma instância do objeto XMLHttpRequest
      const xhr = new XMLHttpRequest();

      // Abrir a conexão com o método GET e a URL desejada
      xhr.open('GET', url, true);

      const { key, vendor_id } = await getVendorApiKey();

      xhr.setRequestHeader('x-vendor-api-key', key);
      xhr.setRequestHeader('x-vendor-id', vendor_id);

      // Definir o tipo de resposta como array buffer
      xhr.responseType = 'arraybuffer';

      // Enviar a requisição
      xhr.send();

      // Manipulador de eventos para quando a requisição estiver concluída
      // eslint-disable-next-line func-names
      xhr.onload = function () {
        if (xhr.status === 200) {
          // Criar um Blob com os dados da resposta
          const blob = new Blob([xhr.response], { type: 'application/csv' });

          // Criar um link para download e simular um clique nele
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'seu-arquivo.csv';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Erro ao fazer o download do arquivo CSV', error);
    }
  };

  const queryVendorCourses = async (): Promise<any> => {
    const response = await sendRequest(
      `${process.env.REACT_APP_API_MKT_URL}/api/v1/courses`,
      'GET',
    );
    const { data } = response;

    return data.data.map((course: any) => {
      return {
        label: course.name,
        value: course.id,
      };
    });
  };

  const queryVendorEventTypes = async (): Promise<any> => {
    const response = await sendRequest(
      `${process.env.REACT_APP_API_MKT_URL}/api/v1/event-types`,
      'GET',
    );
    const { data } = response;

    return data.map((event_type: any) => {
      return {
        label: event_type.nicename,
        value: event_type.id,
      };
    });
  };

  const queryVendorLeadContacts = async (
    lead_id: number,
    type: string,
  ): Promise<any> => {
    const response = await sendRequest(
      `${process.env.REACT_APP_API_MKT_URL}/api/v1/lead-events/${lead_id}/${type}`,
      'GET',
    );
    const { data } = response;
    return data.data;
  };

  const queryVendorLeadRecentActivities = async (
    lead_id: number,
  ): Promise<any> => {
    const response = await sendRequest(
      `${process.env.REACT_APP_API_MKT_URL}/api/v1/lead-events/${lead_id}`,
      'GET',
    );
    const { data } = response;
    return data.data;
  };

  return (
    <VendorContext.Provider
      value={{
        getVendorApiKey,
        sendRequest,
        downloadFile,
        queryVendorCourses,
        queryVendorEventTypes,
        queryVendorLeadContacts,
        queryVendorLeadRecentActivities,
      }}
    >
      {children}
    </VendorContext.Provider>
  );
};

export default VendorContext;
