import React, { useState } from 'react';
import ReactSelect from 'react-select';

import {
  Button,
  Input,
  Icon,
  Grid,
  FormControl,
  Drawer,
  FormLabel,
  DrawerBody,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerFooter,
  DrawerContent,
  Text,
} from '@chakra-ui/react';

import { Plus as PlusIcon } from '@styled-icons/bootstrap/Plus';
import { CloseCircleOutline as CloseIcon } from '@styled-icons/evaicons-outline/CloseCircleOutline';
import { Filter as FilterIcon } from '@styled-icons/fa-solid/Filter';

import { Actions } from 'components/Page';

import './style.css';
import Switch from './components/Switch';

export interface ParamItem {
  name: string;
  description: string;
  default_value: string;
  urlencode: boolean;
}

interface PropsParamsBuilder {
  label: string;
  value: Array<ParamItem>;
  setValue: (newValue: Array<ParamItem>) => void;
}

export const getIntegrationTypeName = (type: string): string => {
  switch (type) {
    case 'active-campaign':
      return 'Active Campaign';
    case 'webhook':
      return 'Webhook';
    default:
      return 'Unknown';
  }
};

export const ParamsBuilder: React.FC<PropsParamsBuilder> = ({
  label,
  value,
  setValue,
}) => {
  const removeItem = (index: number): any => {
    const newValue = [...value];
    newValue.splice(index, 1);
    setValue(newValue);
  };

  const updateName = (index: number, data: string): any => {
    const newValue: Array<ParamItem> = [...value];
    newValue[index].name = data;
    setValue(newValue);
  };

  const updateDescription = (index: number, data: string): any => {
    const newValue: Array<ParamItem> = [...value];
    newValue[index].description = data;
    setValue(newValue);
  };

  const updateDefaultValue = (index: number, data: string): any => {
    const newValue: Array<ParamItem> = [...value];
    newValue[index].default_value = data;
    setValue(newValue);
  };

  const updateUrlencode = (index: number, data: boolean): any => {
    const newValue: Array<ParamItem> = [...value];
    newValue[index].urlencode = data;
    setValue(newValue);
  };

  return (
    <>
      <div className="params-builder-header">
        <FormLabel>{label}</FormLabel>
        <Actions
          options={[
            {
              isActive: false,
              title: 'Novo',
              onClick: () =>
                setValue([
                  ...value,
                  {
                    name: '',
                    description: '',
                    default_value: '',
                    urlencode: false,
                  },
                ]),
              colorScheme: 'green',
              icon: <Icon as={PlusIcon} />,
            },
          ]}
        />
      </div>
      {value.length === 0 && <Text>Sem parâmetros cadastrados.</Text>}
      {value.map((item, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className="params-builder-item" key={index}>
            <div className="params-builder-item-row">
              <Text>Nome</Text>
              <Input
                value={item.name}
                onChange={(e) => updateName(index, e.target.value)}
              />
            </div>
            <div className="params-builder-item-row">
              <Text>Descrição</Text>
              <Input
                value={item.description}
                onChange={(e) => updateDescription(index, e.target.value)}
              />
            </div>
            <div className="params-builder-item-row">
              <Text>Valor padrão</Text>
              <Input
                value={item.default_value}
                onChange={(e) => updateDefaultValue(index, e.target.value)}
                placeholder="Opcional"
              />
            </div>
            <div className="params-builder-item-row toggle">
              <Switch
                value={!!item.urlencode}
                setValue={(encode) => updateUrlencode(index, encode)}
              />
              <Text width="auto !important">
                Gerar variável adicionar com urlencode? <br />
                Uso: <i>{'{{nome_da_variavel_encoded}}'}</i>
              </Text>
            </div>
            <Button
              onClick={() => {
                if (
                  // eslint-disable-next-line no-alert
                  window.confirm(
                    'Tem certeza que deseja remover essa variável?',
                  )
                ) {
                  removeItem(index);
                }
              }}
              colorScheme="red"
            >
              Deletar
            </Button>
          </div>
        );
      })}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function objToFormData(obj: any): FormData {
  const formData = new FormData();

  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((value: any) => {
        formData.append(`${key}[]`, value);
      });
    } else {
      formData.append(key, obj[key]);
    }
  });

  return formData;
}

export interface statusDictionary {
  processing: string;
  dispatched: string;
  delivered: string;
  bounced: string;
  spam: string;
  open: string;
  clicked: string;
  failed: string;
  pending: string;
  read: string;
  ['fake-pending']: string;
  ['fake-sent']: string;
  ['pre-venda']: string;
  comprado: string;
  cancelado: string;
  abandonado: string;
  recuperado: string;
}

export const status: statusDictionary = {
  processing: 'Processando',
  dispatched: 'Enviado',
  delivered: 'Entregue',
  bounced: 'Devolvido',
  spam: 'Spam',
  open: 'Aberto',
  clicked: 'Clicado',
  failed: 'Falhou',
  pending: 'Pendente',
  read: 'Lido',
  'fake-pending': 'Pendente - Falso',
  'fake-sent': 'Enviado - Falso',
  'pre-venda': 'Pre-venda',
  comprado: 'Comprado',
  cancelado: 'Cancelado',
  abandonado: 'Abandonado',
  recuperado: 'Recuperado',
};
export const statusColor: statusDictionary = {
  processing: 'gold',
  dispatched: 'blue',
  delivered: 'green',
  bounced: 'red',
  spam: 'red',
  open: 'blue',
  clicked: 'blue',
  failed: 'red',
  pending: 'gold',
  read: 'blue',
  'fake-pending': 'gold',
  'fake-sent': 'blue',
  'pre-venda': 'gold',
  comprado: 'blue',
  cancelado: 'red',
  abandonado: 'red',
  recuperado: 'green',
};

export const replaceVariablesInTemplate = (
  variables: string,
  template: string,
): string => {
  const obj = JSON.parse(variables);
  let message = template;
  Object.keys(obj).forEach((key) => {
    message = message.replaceAll(`{{${key}}}`, obj[key]);
  });

  return message;
};

export const formatPhone = (phone: string): string => {
  let newPhone: string = phone.replace(/\D/g, '');

  // Verifica se o telefone já está no formato final de 5511999999999
  if (newPhone.length !== 13) {
    // Verifica se falta apenas o DDI
    if (newPhone.length === 11) {
      newPhone = `55${newPhone}`;
    } else {
      newPhone = newPhone.padEnd(13, '-');
    }
  }

  const ddi = newPhone.slice(0, 2);
  const ddd = newPhone.slice(2, 4);
  const phoneStart = newPhone.slice(4, 9);
  const phoneEnd = newPhone.slice(9);

  return `+${ddi} (${ddd}) ${phoneStart}-${phoneEnd}`;
};

export const requestHeaders: HeadersInit = new Headers();
requestHeaders.set('Accept', 'application/json');
requestHeaders.set('Content-Type', 'application/json');
requestHeaders.set('x-api-key', `${process.env.REACT_APP_API_MKT_KEY}`);

export const requestHeadersFormData: HeadersInit = new Headers();
requestHeadersFormData.set('x-api-key', `${process.env.REACT_APP_API_MKT_KEY}`);

interface PropsFilters {
  filtersInUse: number;
  filter: (start: string, end: string, statusValue: string) => void;
  availableValues: Array<string> | undefined;
}

export const Filters: React.FC<PropsFilters> = ({
  filtersInUse = 0,
  filter,
  availableValues = undefined,
}) => {
  const [opened, setOpened] = useState(false);
  const [startField, setStartField] = useState('');
  const [endField, setEndField] = useState('');
  const [statusField, setStatusField] = useState('');

  const options: Array<{ label: string; value: string }> = [];
  Object.entries(status).forEach((item) => {
    if (!availableValues || availableValues.includes(item[0])) {
      options.push({ label: item[1], value: item[0] });
    }
  });

  return (
    <>
      <Button
        colorScheme="blue"
        leftIcon={<Icon as={FilterIcon} />}
        onClick={() => {
          setOpened(true);
        }}
        size="md"
        width={{ base: '100%', md: 160 }}
      >
        Filtros {filtersInUse ? `(${filtersInUse})` : ''}
      </Button>
      {filtersInUse !== 0 && (
        <Button
          colorScheme="gray"
          size="md"
          width={{ base: '100%', md: 160 }}
          ml={{ base: 3 }}
          onClick={() => {
            setStartField('');
            setEndField('');
            setStatusField('');
            filter('', '', '');
          }}
        >
          Limpar filtros
        </Button>
      )}
      <Drawer
        isOpen={opened}
        placement="right"
        onClose={() => {
          setOpened(false);
        }}
        size="sm"
        isFullHeight
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Filtros</DrawerHeader>
            <DrawerBody paddingBottom={20}>
              <form
                onSubmit={() => {
                  filter(startField, endField, statusField);
                  setOpened(false);
                }}
              >
                <Grid
                  templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
                  columnGap={2}
                  rowGap={2}
                >
                  <FormControl>
                    <FormLabel>Data (Início)</FormLabel>
                    <Input
                      name="start"
                      type="datetime-local"
                      value={startField}
                      onChangeCapture={(e) => {
                        setStartField((e.target as HTMLInputElement).value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Data (Fim)</FormLabel>
                    <Input
                      name="end"
                      type="datetime-local"
                      value={endField}
                      onChangeCapture={(e) => {
                        setEndField((e.target as HTMLInputElement).value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Status</FormLabel>
                    <div className="box-react-select">
                      <ReactSelect
                        placeholder="Selecione"
                        name="status"
                        value={options.filter((s) => statusField === s.value)}
                        rules={{ required: true }}
                        options={options}
                        onChange={(option) => {
                          setStatusField(option ? option.value : '');
                        }}
                      />
                      <button
                        type="button"
                        onClick={() => setStatusField('')}
                        className="button-close"
                        disabled={!statusField}
                      >
                        <CloseIcon width={25} height={25} />
                      </button>
                    </div>
                  </FormControl>
                </Grid>
              </form>
            </DrawerBody>
            <DrawerFooter>
              <Button
                variant="outline"
                mr={3}
                onClick={() => {
                  setOpened(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => {
                  filter(startField, endField, statusField);
                  setOpened(false);
                }}
              >
                Aplicar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export function sleep(ms: number): Promise<Promise<void>> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
